.popup-box {
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
}

.box {
    position: relative;
    width: 50%;
    min-height: 300px;
    background-color: #fff;
    border: 1px solid #999;
    border-radius: 4px;
    margin: 20px auto;
    padding: 20px;
    overflow: auto;
}

.btn-close {
    cursor: pointer;
    border: 1px solid #999;
    border-radius: 50%;
    position: fixed;
    right: calc(30% - 30px);
    top: 15px;


}