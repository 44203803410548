.feature {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.feature-icon {
    position: relative;
    margin-right: 2rem;
}

.inner-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.feature-text h3 {
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;
    color: #C73866;
}

.feature-text p {
    font-size: 1rem;
}