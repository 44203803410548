section#meter {
    background: url(../../imagess/screenshot.png);
    text-align: center;
    background-size: cover;
    background-attachment: fixed;
    color: var(--color-white);
}

.meter h2 {
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.4;
    margin: 1.5rem 0;
    padding-bottom: 1rem;
}

@media screen and (max-width:450px) {
    form input {
        width:60%
    }
    
}

@media screen and (max-width:600px) {
    form input {
        width:60%
    }
    
}