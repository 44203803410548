.service-content {
    display: flex;
    justify-content: center;
}

.service-left {
    flex: 1;
    text-align: center;

}

.service-left img {
    width: 95%;
}

.service-right {
    flex: 1;
}

@media screen and (max-width:600px) {
    
    .service-content{
        flex-direction: column;
    }
}