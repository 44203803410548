@import url(https://fonts.googleapis.com/css?family=Oswald);


@import url(https://fonts.googleapis.com/css?family=Open+Sans);



h2 {
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-size: 26px;
  line-height: 26px;
  margin: 25px 0 15px 0;
  display: block;
  -webkit-margin-before: 0.83em;
  -webkit-margin-after: 0.83em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;  
 }


body {
font: normal 13px/24px  'Open Sans', sans-serif;
}

.body-left {
  padding-left: 20px;
  padding-right: 20px;
  text-align: justify;

}