.btn-style {
  width: 12rem;
  height: 4rem;
  border-radius: 2rem;
  color: #fff;
  background-color:  #C73866;
  text-transform: capitalize;
  font-size: 1.6rem;
}

.btn-style:first-child {
  margin-right: 2rem;
}

.btn-style:hover {
  border: 0.1rem solid #C73866;
  background-color: transparent;
  color: var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem rgba(183, 171, 255, 0.5);
}

.contactus-section {
  margin: 1rem 0;
}

.contact-leftside img{
  min-width: 60%;
  max-height: 50rem;
  height: auto;
}

.contact-input-feild{
  margin-bottom: 0.1rem;

}

.form-control {
  margin-bottom: 0.5rem;
}

.contact-rightside form .form-control{
  border: 1px solid #0b172a;
  -webkit-appearance: none;
  height: 5rem;
  border-radius: 00.5rem;
  font-size: 1.6rem;
  padding-left: 1rem;
  font-weight: lighter;
}

.contact-rightside form .form-check-input {
  width: 2em;
  height: 1em;
}
