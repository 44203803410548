#header {
    position: relative;
}

.header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.header h1 {
    font-size: 2rem;
    font-weight: 800;
    line-height: 1.0;
    color: var(--color-white);
    position: relative;
    padding-bottom: 2rem;
}

.header h1 span {
    display: block;
}

h1 span:nth-child(2),
h1 span:nth-child(3) {
    font-weight: 400;
}

.header h1::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 65%;
    height: 1px;
    background: var(--color-white);
}

.header-left p {
    margin: 3rem 0;
    width: 70%;
}

.header-right img {
    width: 130%;
}

.floating-icon {
    background: rgb(161, 245, 7);
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    bottom: 0;
}

.mouse {
    transform: translateY(-25%);
    animation: bounce .8s ease infinite alternate;
}

@keyframes bounce {

    0% {
        transform: translateY(25%);
    }
    
}

@media screen and 
(max-width:769px) {
    .header h1 {
        font-size: 2rem;
    }
}

@media screen and 
(max-width:600px) {
    .header {
        flex-direction: column;
    }
    .header-left,
    .header-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 auto;
    }
    .header h1::after {
        left: 50%;
        transform: translateX(-50%);
    }

    .header-right img {
        width: 100%;
    }
}
