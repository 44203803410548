 .a {
     font-size: 1.6rem;
     font-weight: 300;
     color: var(--color-white);
     text-decoration: none;
     margin-right: 1rem;
 }

 .a:hover {
     color: white;

 }

 a.btn {
     border-radius: 5px;
     margin-left: 3rem;
     font-weight: 500;
     padding: 5px 8px;
 }

 a.btn-dark {
     border: 2px solid var(--color-white);
     background: transparent;
 }

 a.btn-orange {
     border: 2px solid transparent;
     background: black;
     color: var(--color-white);
 }
 a.btn-orange:hover {
    border: 2px solid transparent;
    background: white;
    color: black;
}